<script>
import Offer from '../Offer'
import {handleWith} from "@/mixins/globalMixins";
export default {
	components: {
		Offer
	},
	name: 'doradztwo',
	mixins: [handleWith],
}
</script>

<template>
	<Offer>
		<template #banner>
			<img src="./img/doradztwo.jpg" class="fixed-image" alt="banner">
		</template>
		<template #button>
			<i v-if="mobile === true" class="fas fa-arrow-left"></i>
			<router-link slot="button" to="/oferta">Wróć do spisu usług</router-link>
		</template>
		<template #small-title>
					<h4>PRAWO PODATKOWE</h4>
				</template>

				<template #title>
					<h2>Bieżące Doradztwo Podatkowe</h2>
				</template>

				<template #text>
					<p>Naszym celem jest zapewnienie klientom bezpieczeństwa podatkowego i minimalizowanie ich obciążeń podatkowych w zgodzie z obowiązującymi przepisami.
						<br>
						<br>
					Co ważne możesz liczyć na naszą szybką reakcję i wsparcie, bez względu czy potrzebujesz konsultacji online, szybkiej rozmowy telefonicznej, krótkiego e-maila, czy też obszernej opinii czy interpretacji podatkowej.
					<br>
					<br>
						Oferujemy bieżące doradztwo m.in. w zakresie – PIT, CIT, VAT, PCC, podatku u źródła (WHT) podatku od nieruchomości, które obejmuje w szczególności:

					</p>
				</template>
		<template #list>
					<ul class="offer__list">
						<li class="offer__list-item">
							Bieżące wsparcie w aktualnych problemach podatkowych;
						</li><li class="offer__list-item">
						Opiniowanie umów w zakresie ich konsekwencji podatkowych;

						</li><li class="offer__list-item">
						Sporządzanie i składanie w imieniu klienta zarówno wniosków o interpretację indywidualną jak też wniosków o wiążącą informację stawkową (WIS);

						</li>
					</ul>
				</template>
	</Offer>
</template>

<style scoped lang="scss">
.fixed-image {
	object-fit: cover;
}
</style>
